@import './styles/_fonts';
@import './styles/_forms';
@import './styles/_general';
@import './styles/_vars';

body {
  color: $mdc-text;
  font-family: 'Calibri';
  background: #f4f4f4;
  padding-top: 100px;
}

.container {
  width: 1200px;
  max-width: calc(100% - 40px);
  margin: 0 auto;
}

#steps {
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 50px 0;
  }
}

.step {
  background: white;
  width: 630px;
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;

  &__header {
    margin-bottom: 36px;
  }

  &-title {
    font-family: 'Franklin Gothic';
    font-size: 24px;
    font-weight: normal;
    margin: 0;
  }

  .progress {
    text-align: center;
  }
}
