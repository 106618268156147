.step-bank {
  width: 800px;
  background: none;
  padding: 0;

  .flinks-connect {
    border: none;
    width: 100%;
    height: 820px;
    position: relative;

    @media (max-width: 568px) {
      height: 540px;
    }
  }
}
