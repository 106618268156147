@import 'styles/_vars';

.step-form {
  .step__header {
    .step-title {
      margin-bottom: 18px;
    }

    .step-description p {
      line-height: 28px;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  .step__content {
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      @media (max-width: 450px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .form__submit {
      text-align: center;
    }

    .terms-and-conditions {
      color: #959494;
      font-size: 16px;
      display: block;
      margin-top: 30px;

      a {
        color: $mdc-primary;
        transition: color 150ms;

        &:hover {
          color: $mdc-text;
        }
      }
    }
  }
}
