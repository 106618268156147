@import 'styles/_vars';

#mainFooter {
  border-top: 10px solid #d4d4d4;
  padding: 100px 0 40px;

  @media (max-width: 980px) {
    padding: 70px 0 40px;
  }

  .row {
    &-1 {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      @media (max-width: 568px) {
        display: block;
      }

      .col {
        &__location,
        &__contact {
          width: 270px;

          @media (max-width: 800px) {
            width: auto;
          }

          @media (max-width: 768px) {
            width: 50%;
          }
        }

        @media (max-width: 768px) {
          &.col__location {
            padding-right: 24px;
          }

          &.col__contact {
            padding-left: 24px;
          }
        }

        &__title {
          border-bottom: 1px solid $mdc-primary;
          display: flex;
          align-items: center;
          margin: 0 0 24px;
          padding-bottom: 12px;

          .icon {
            display: flex;
            align-items: center;
            height: 20px;
            margin-right: 12px;
          }
        }

        &__content p {
          line-height: 28px;
        }

        .social-links .item {
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          transition: background-color 150ms, box-shadow 150ms;

          svg {
            fill: $mdc-primary;
            display: block;
            transition: fill 150ms;
          }

          &:hover {
            background: $mdc-primary;
            box-shadow: 0 6px 14px fade-out(black, 0.85);

            svg {
              fill: white;
            }
          }
        }

        &__links {
          @media (min-width: 569px) and (max-width: 768px) {
            width: 100%;
            margin-top: 50px;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            @media (min-width: 569px) and (max-width: 768px) {
              text-align: center;
            }
          }

          .item {
            @media (min-width: 569px) and (max-width: 768px) {
              display: inline-block;
            }

            &:not(:last-child) {
              margin-bottom: 12px;

              @media (min-width: 569px) and (max-width: 768px) {
                margin: 0;
                margin-right: 20px;
              }
            }

            &__link {
              color: $mdc-text;
              text-decoration: none;
              transition: color 150ms;

              &:hover {
                color: $mdc-primary;
              }
            }
          }
        }

        @media (max-width: 568px) {
          width: 100%;
          margin: 0;
          padding: 0 !important;

          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }

    &-2 {
      display: flex;
      align-items: center;
      border-top: 1px solid fade-out(black, 0.87);
      margin: 42px 0 30px;
      padding-top: 35px;

      @media (max-width: 568px) {
        display: block;
      }

      .col {
        &__info {
          flex-grow: 1;
          padding-right: 100px;

          @media (max-width: 850px) {
            padding-right: 50px;
          }

          @media (max-width: 568px) {
            text-align: center;
            padding: 0;
          }

          p {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
          }
        }

        &__certificates {
          display: flex;
          align-items: center;

          @media (max-width: 568px) {
            justify-content: center;
            margin-top: 24px;
          }

          img:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    &-3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 568px) {
        text-align: center;
        display: block;
      }

      .col {
        &__copyright {
          font-size: 14px;
        }

        &__developer {
          display: flex;
          align-items: center;

          @media (max-width: 568px) {
            justify-content: center;
            margin-top: 20px;
          }

          small {
            color: $mdc-primary;
            font-size: 14px;
            display: block;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
