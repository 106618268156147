@import 'styles/_vars';

.form {
  &__field {
    &-label {
      font-size: 16px;
      display: block;
      margin-bottom: 8px;

      .tooltip {
        position: relative;
        top: -1px;

        @media (max-width: 768px) {
          display: block;
        }

        &__toggler {
          color: white;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          text-align: center;
          background: $mdc-primary;
          border-radius: 7px;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-left: 8px;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__content {
          color: white;
          font-size: 14px;
          background: fade-out($mdc-text, 0.2);
          border-radius: 4px;
          width: 200px;
          position: absolute;
          top: 0;
          left: calc(100% + 5px);
          padding: 8px 10px 7px;
          box-shadow: 0 2px 6px fade-out(black, 0.9);
          opacity: 0;
          visibility: hidden;
          transition: opacity 150ms, visibility 150ms;

          @media (max-width: 768px) {
            color: fade-out(black, 0.5);
            background: none;
            display: block;
            width: 100%;
            position: static;
            opacity: 1;
            visibility: visible;
            margin: 8px 0 14px;
            padding: 0;
            box-shadow: none;
          }

          p {
            margin: 0;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }

        &:hover .tooltip__content {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &--required .form__field-label .text::after {
      content: '*';
      color: $mdc-error;
      margin-left: 2px;
    }

    .field {
      color: $mdc-text;
      font-family: 'Calibri';
      font-size: 18px;
      border: none;
      border-bottom: 1px solid fade-out(black, 0.7);
      display: block;
      width: 100%;
      padding: 0 0 4px;
      transition: border-color 150ms;

      &::placeholder {
        color: $mdc-text;
        opacity: 0.4;
      }
    }

    &-feedback {
      color: $mdc-error;
      font-size: 14px;
      font-weight: bold;
      display: block;
      height: 14px;
      margin-top: 8px;
      transition: opacity 150ms;
      will-change: opacity;
      pointer-events: none;
    }

    &:not(.form__field--invalid) .form__field-feedback {
      opacity: 0;
    }

    &--invalid .field {
      border-color: $mdc-error;
    }
  }
}
