@import 'styles/_vars';

#mainHeader {
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  @media (max-width: 568px) {
    box-shadow: 0 1px 4px fade-out(black, 0.9);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    @media (max-width: 568px) {
      display: block;
      height: auto;
      padding: 16px 0;
    }

    .left {
      display: flex;
      align-items: center;

      @media (max-width: 568px) {
        display: block;
      }

      .logo {
        display: block;

        @media (max-width: 568px) {
          text-align: center;
        }

        img {
          display: inline-block;
          height: 36px;
        }
      }

      .step-info {
        border-left: 2px solid $mdc-primary;
        margin-left: 34px;
        padding: 10px 0 10px 36px;

        @media (max-width: 568px) {
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 14px 0 0;
          padding: 0;
        }

        .top {
          font-size: 16px;
        }

        .step-name {
          font-family: 'Franklin Gothic';
          font-size: 18px;
          font-weight: normal;
          height: 21px;
          margin: 0;

          &.loading {
            color: fade-out(black, 0.8);
          }
        }
      }
    }

    .right .step-indicator span {
      font-variant-numeric: tabular-nums;
      display: inline-block;

      &:nth-child(1) {
        font-size: 16px;
        margin-right: 10px;
      }

      &:nth-child(2) {
        font-weight: normal;
        font-family: 'Franklin Gothic';
      }
    }
  }
}
