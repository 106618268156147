@import './_vars';

.el-img {
  display: block;
  pointer-events: none;
  user-select: none;

  &__cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.btn {
  color: white;
  font-family: 'Calibri';
  font-size: 16px;
  font-weight: bold;
  line-height: 54px;
  text-decoration: none;
  background: white;
  border-radius: 25px;
  border: none;
  display: inline-block;
  height: 50px;
  position: relative;
  padding: 0 50px;
  overflow: hidden;
  transition: color 150ms, box-shadow 150ms;

  &::before {
    content: '';
    background: linear-gradient(to right, #ea6300, #f47a20);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 150ms;
    will-change: opacity;
  }

  span {
    position: relative;
    top: -1px;
    z-index: 2;
  }

  &:hover {
    color: $mdc-text;
    box-shadow: 0 6px 14px fade-out(black, 0.85);

    &::before {
      opacity: 0;
    }
  }
}
