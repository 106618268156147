@import 'styles/_vars';

.rich-text {
  > * {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Franklin Gothic';
  }

  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 12px;
    text-transform: uppercase;
  }

  p {
    line-height: 28px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  a {
    color: $mdc-primary;
    text-decoration: underline;
    transition: color 150ms;

    &:hover {
      color: $mdc-text;
    }
  }

  blockquote {
    border-left: 4px solid fade-out(black, 0.9);
    max-width: 100%;
    padding: 6px 0 6px 40px;

    p {
      font-size: 18px;
      line-height: 32px;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  ul,
  ol {
    line-height: 28px;
  }

  pre {
    white-space: pre-wrap;
  }

  > *:first-child {
    margin-top: 0;
  }
}
