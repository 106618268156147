.form__field.type-custom-select {
  position: relative;

  .toggler {
    text-align: left;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 100%;
      min-width: 0;
      overflow: hidden;
    }
  }

  .options {
    background: white;
    min-width: 100%;
    max-height: 227px;
    position: absolute;
    top: calc(100% + 1px);
    padding: 5px 0;
    z-index: 50;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    box-shadow: 0 10px 20px fade-out(black, 0.9);
    transition: opacity 250ms, visibility 250ms;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    .option {
      user-select: none;

      &:not(:last-child) {
        margin-bottom: 1px;
      }

      &__field {
        display: none;
      }

      &__label {
        font-size: 14px;
        display: block;
        padding: 8px 10px;
        cursor: pointer;
        transition: color 250ms, background-color 250ms;

        &:hover {
          background: fade-out(black, 0.98);
        }
      }

      &__field:checked ~ .option__label {
        font-weight: bold;
      }

      &__select-all .option__label {
        background: fade-out(black, 0.98);
      }
    }
  }
}
