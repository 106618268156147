.step-success {
  padding: 50px 30px;

  .step__header {
    text-align: center;

    .icon img {
      display: inline-block;
    }

    .step-title {
      margin-top: 10px;
    }
  }
}
