// Calibri
@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
       url('../assets/fonts/Calibri.woff2') format('woff2'),
       url('../assets/fonts/Calibri.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Bold'), local('Calibri-Bold'),
       url('../assets/fonts/Calibri-Bold.woff2') format('woff2'),
       url('../assets/fonts/Calibri-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

// Franklin Gothic
@font-face {
  font-family: 'Franklin Gothic';
  src: local('Franklin Gothic Heavy'), local('FranklinGothic-Heavy'),
       url('../assets/fonts/FranklinGothic-Heavy.woff2') format('woff2'),
       url('../assets/fonts/FranklinGothic-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Franklin Gothic';
  src: local('Franklin Gothic Demi'), local('FranklinGothic-Demi'),
       url('../assets/fonts/FranklinGothic-Demi.woff2') format('woff2'),
       url('../assets/fonts/FranklinGothic-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
